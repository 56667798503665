import React, { useEffect } from 'react';
import axios from 'axios';

const NameList = ({ names, setNames }) => {
  useEffect(() => {
    const fetchNames = async () => {
      try {
        const response = await axios.get('http://localhost:5000/names');
        setNames(response.data);
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    };
    fetchNames();
  }, [setNames]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/names/${id}`);
      setNames(names.filter(name => name._id !== id));
    } catch (error) {
      console.error('Error deleting name:', error);
    }
  };

  return (
    <ul>
      {names.map(name => (
        <li key={name._id}>
          {name.name} <button onClick={() => handleDelete(name._id)}>Eliminar</button>
        </li>
      ))}
    </ul>
  );
};

export default NameList;