import React, { useState, useEffect } from 'react';
import AddNameForm from './components/AddNameForm';
import NameList from './components/NameList';
import axios from 'axios';

const App = () => {
  const [names, setNames] = useState([]);

  const fetchNames = async () => {
    const response = await axios.get('http://localhost:5000/names');
    setNames(response.data);
  };

  useEffect(() => {
    fetchNames();
  }, []);

  const handleAdd = (newName) => {
    setNames([...names, newName]);
  };

  return (
    <div>
      <h1>Registro de Nombres</h1>
      <AddNameForm onAdd={handleAdd} />
      <NameList names={names} setNames={setNames} />
    </div>
  );
};

export default App;


